import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import loadable from '@loadable/component';
import { CartFlyout as Cart } from '@westwing/ui-kit/ContentFlyout/CartFlyout';
import { CartProps } from '../CartContent';
import CartFlyoutProduct from './CartFlyoutProduct';
import useTranslator from 'Client/hooks/useTranslator';
import { OverlayId } from 'Client/components/Overlays/types';
import { hideOverlay } from 'Client/redux/header/overlays/actions';
import StateInterface from 'Client/redux/types';
import { getOverlayById } from 'Client/redux/header/overlays/selectors';
import BellaHybridLink from 'Client/components/Generic/Links/BellaHybridLink';
import { useBellaSelector } from 'Client/redux/hooks';

const ContentFlyout = loadable(() => import('@westwing/ui-kit/ContentFlyout'));

interface Props extends CartProps {
    cartCount: number;
}

const CartFlyout: React.FC<Props> = ({ cartUrl, carts, subtotal, cartCount }) => {
    const t = useTranslator();
    const dispatch = useDispatch();

    const onOverlayClose = () => {
        dispatch(hideOverlay(OverlayId.DESKTOP_CART_OVERLAY));
    };

    const discountColor = useBellaSelector(state => state.configurations.specialSales.strikeThroughColor);
    const isActive = useSelector<StateInterface, boolean>(
        state => getOverlayById(state.header.overlays, OverlayId.DESKTOP_CART_OVERLAY).isActive
    );
    const showCartFlyout = cartCount > 0 && isActive;
    if (!showCartFlyout) {
        return null;
    }

    return (
        <ContentFlyout isActive={isActive} rightPosition={2} onOverlayClose={onOverlayClose} propagateOutsideClicks>
            <Cart
                amountOfItems={cartCount}
                maxAmountOfItems={2}
                cartUrl={cartUrl}
                subtotal={subtotal}
                totalSumLabel={t('Total sum')}
                productQuantityLabel={t('Quantity')}
                goToCartLabel={t('Go to basket')}
                seeProductsLabel={t('All products in your cart')}
                dataTestId="cartProductsWrapper"
                goToCartComponent={BellaHybridLink}
                goToCartProps={{ to: cartUrl }}
                allProductsLinkComponent={BellaHybridLink}
                allProductsLinkProps={{ to: cartUrl }}
            >
                {carts.map(product => (
                    <CartFlyoutProduct
                        key={product.name}
                        cartUrl={cartUrl}
                        discountColor={discountColor}
                        {...product}
                    />
                ))}
            </Cart>
        </ContentFlyout>
    );
};

export default CartFlyout;
