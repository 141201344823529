import React, { useMemo } from 'react';
import { ObjectFit } from '@westwing/ui-kit/Picture/Image';
import { CartProduct } from '@westwing/ui-kit/ContentFlyout/CartFlyout';
import BellaHybridLink from 'Client/components/Generic/Links/BellaHybridLink';
import useBellaTranslator from 'Client/hooks/useTranslator';
import { ProductImage } from 'Client/components/Product/ProductImage';
import { useBellaDispatch, useBellaSelector } from 'Client/redux/hooks';
import { setPDPProductKnownInfoAction } from 'Client/redux/productdetail/actions';
import { PrimaryProductInfo } from 'AppShell/appshell_types';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';
import { CartProductItem } from 'Client/redux/cart/types';
import useConfig from 'AppShell/hooks/useConfig';
import useRouter from 'AppShell/hooks/useRouter';

interface CartProductProps extends CartProductItem {
    cartUrl: string;
    discountColor?: string;
}

const CartFlyoutProduct = ({
    brand,
    price,
    imagePath,
    imageTag,
    quantity,
    name,
    cartUrl,
    productUrl = cartUrl,
    originalPrice,
    discountColor,
    isSustainable,
    isNew,
    soldOut,
    origin,
}: CartProductProps) => {
    const t = useBellaTranslator();
    const dispatch = useBellaDispatch();
    const { p100PdpShowMoodImageFirst } = useFeatureToggle();
    const { staticCmsHost } = useConfig();
    const { location } = useRouter();

    const PDPProduct: PrimaryProductInfo = useMemo(
        () => ({
            brand,
            price,
            name,
            originalPrice,
            productImage: {
                path: `${staticCmsHost}image/upload/${imagePath}`,
                seoUrl: '',
                tag: imageTag,
            },
            isSustainable,
            isNew,
            soldOut,
        }),
        [brand, imagePath, imageTag, isNew, isSustainable, name, originalPrice, price, soldOut, staticCmsHost]
    );

    const handleClick = () => {
        if (!productUrl.includes(location.pathname)) {
            dispatch(setPDPProductKnownInfoAction(PDPProduct, !!p100PdpShowMoodImageFirst));
        }
    };

    const { isCartExpired } = useBellaSelector(state => state.header.cart);
    const isClubItem = origin === 'club';

    return (
        <CartProduct
            dataTestId="cartProduct"
            productImageComponent={ProductImage}
            productImageProps={{
                src: imagePath,
                alt: `${name} image`,
                imageTag,
                imageType: 'CART',
                objectFit: ObjectFit.CONTAIN,
            }}
            productLinkComponent={BellaHybridLink}
            productLinkProps={{ to: productUrl, onClick: handleClick }}
            quantityLabel={t('Quantity')}
            quantity={quantity}
            price={price}
            originalPrice={originalPrice}
            discountColor={discountColor}
            brand={brand}
            name={name}
            origin={origin}
            isCartExpired={isCartExpired}
            isClubItem={isClubItem}
        />
    );
};
export default CartFlyoutProduct;
